@import url("https://fonts.googleapis.com/css2?family=Epilogue:wght@400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lora:400,700&family=Merriweather&family=Nunito&family=Open+Sans&family=Sono&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.linear-gradient {
  background: linear-gradient(
    179.14deg,
    rgba(32, 18, 63, 0) -7.14%,
    #000000 87.01%
  );
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  filter: invert(0.8);
}

html {
  font-size: 16px;
}

body {
  background-color: #F6F6F7 !important;
}