/* styles.css */
.sidebar {
  height: 100vh;
  width: 250px;
  position: absolute;
  z-index: 100;
  background-color: #F6F6F7 !important;
  border-right: 1px solid #dee2e6;
}

.sidebar-element {
  text-decoration: none !important;
}

.sidebar-element:hover {
  width: '100%';
  background-color: #edeeef !important;
}

.main-content-with-sidebar {
  padding-left: 250px;
}

@media (max-width: 767.98px) {
  .sidebar {
    display: none;
  }

  .main-content-with-sidebar {
    padding-left: 0;
  }
}

/* styles.css */
.guide {
  height: 100vh;
  width: 250px;
  position: absolute;
  right: 0;
  z-index: 100;
  border-left: 1px solid #dee2e6;
  background-color: #fff2cd;
  color: #856405;
}

.main-content-with-guide {
  padding-right: 250px;
}

@media (max-width: 767.98px) {
  .guide {
    display: none;
  }

  .main-content-with-guide {
    padding-right: 0;
  }
}
.relative:hover .tooltip {
  display: block;
  visibility: visible;
  opacity: 1;
}