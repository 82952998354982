/* Login.css */
.btn-primary {
    background-color: #24292e;
    border-color: #24292e;
  }
  
  .btn-primary:hover,
  .btn-primary:focus,
  .btn-primary:active {
    background-color: #1c1f23;
    border-color: #1c1f23;
  }